const id = {
    profileFieldsGroup: {
        basicInformation: 'Informasi Dasar',
        phdRelatedInformation: 'Informasi tentang PhD',
        affiliation: 'Afiliasi',
        socialMedia: 'Media Sosial',
        publications: 'Publikasi Ilmiah',
        popularScienceArticles: 'Artikel Populer Ilmiah',
        communityServices: 'Pengabdian Masyarakat',
        speakerExperiences: 'Pengalaman Sebagai Pembicara',
        yorePremium: 'Pertanyaan Tambahan untuk Member of Yore Premium',
        profilePicture: 'Foto Profil',
        paymentProof: 'Bukti Pembayaran Keanggotaan',
        otherInformation: 'Informasi Lainnya',
    },
    profileFields: {
        membershipType: 'Jenis Keanggotaan',
        fullName: 'Nama Lengkap',
        givenName: 'Nama Depan',
        familyName: 'Nama Belakang',
        nickname: 'Nama Panggilan',
        gender: 'Jenis Kelamin',
        hasDisability: 'Apakah Anda memiliki disabilitas?',
        disability: 'Jenis Disabilitas',
        emailAddress: 'Alamat Email',
        emailAddressHelperText: 'Alamat email pribadi/personal',
        doctrineEmailPreExisted: 'Apakah anda sudah memiliki alamat email Doctrine?',
        doctrineEmailAddress: 'Alamat Email Doctrine',
        doctrineEmailAddressCreated: 'Apakah alamat email Doctrine sudah dibuat?',
        doctrineEmailAddressHelperTextNotExist: 'Alamat email Doctrine yang diinginkan.',
        doctrineEmailAddressHelperTextExisted: 'Alamat email Doctrine yang dimiliki.',
        secondaryEmailAddress: 'Alamat Email Sekunder',
        phoneNumber: 'Nomor WhatsApp (untuk bergabung ke grup WA Doctrine UK)',
        phoneNumberHelperText: 'Nomor telepon yang digunakan untuk WhatsApp. Anda bisa mengubah kode negara dengan menekan ikon bendera.',
        status: 'Status PhD',
        statusHelperText: 'Status Anda terkait dengan Inggris Raya.',
        region: 'Wilayah',
        regionHelperText: 'Wilayah berdasarkan tempat tinggal saat ini atau universitas di Inggris. Klik tombol untuk melihat peta sebagai referensi.',
        UKInstitution: 'Institusi di Inggris Raya',
        proofOfCompletion: 'Bukti Selesai PhD',
        proofOfCompletionHelperText: 'Upload bukti selesai PhD',
        university: 'Universitas (PhD)',
        universityHelperText: 'Universitas tempat Anda melakukan PhD. ' +
            'Jika universitas Anda tidak terdaftar, mohon pilih "Other" dan Anda bisa memasukkan nama universitas anda di field berikutnya.',
        otherUniversity: 'Universitas Lainnya (PhD)',
        programmeName: 'Nama Program PhD (Jurusan)',
        academicEmailAddress: 'Alamat Email di Universitas',
        universityProfileURL: 'URL Profile di Universitas',
        universityProfileURLHelperText: 'Contoh: https://www.ucl.ac.uk/bartlett/construction/people/phd-students/gatot-subroto',
        phdStartDate: 'Tanggal Mulai PhD',
        phdEndDate: 'Tanggal Selesai PhD',
        fundingSource: 'Sumber Pendanaan',
        fundingSourceHelperText: 'Jika tidak tercantum, silakan tulis sendiri.',
        phdResearchTopic: 'Topik Penelitian PhD',
        phdResearchAbstract: 'Abstrak Penelitian PhD',
        epistemologicalDisciplines: 'Bidang Keilmuan',
        epistemologicalDisciplinesOther: 'Bidang Keilmuan Lainnya',
        researchInterests: 'Minat Riset',
        researchInterestsPlaceholder: 'Ketik dan tekan Enter untuk menambahkan minat riset baru',
        methodologicalExpertise: 'Keahlian Metodologi',
        affiliation: 'Instansi / Institusi Afiliasi di Indonesia',
        jobTitle: 'Pekerjaan / Jabatan',
        jobTitleOther: 'Pekerjaan / Jabatan Lainnya',
        facebook: 'URL Facebook atau ID',
        twitter: 'URL Twitter atau Username',
        instagram: 'URL Instagram atau Username',
        linkedIn: 'URL LinkedIn atau ID',
        gitHub: 'URL GitHub atau Username',
        researchGate: 'URL ResearchGate atau Username',
        orcidID: 'ORCID ID (16 digit angka)',
        personalBlog: 'URL Blog Pribadi',
        publicationsHelperText: 'Klik "Cari" untuk mencari publikasi lewat nomor DOI atau ISBN. Klik "Hapus" jika Anda tidak memiliki publikasi ilmiah',
        publicationsInputType: 'Jenis Input',
        publicationsManualInputType: 'Manual (Harap gunakan style referensi Harvard)',
        popularScienceArticlesHelperText: 'Klik "Hapus" jika Anda tidak memiliki artikel populer ilmiah',
        title: 'Judul',
        year: 'Tahun',
        articleURL: 'URL Artikel',
        communityServicesHelperText: 'Klik "Hapus" jika Anda tidak memiliki pengalaman pengabdian masyarakat',
        speakerExperiencesHelperText: 'Klik "Hapus" jika Anda tidak memiliki pengalaman sebagai pembicara',
        skills: 'Jenis keahlian yang ingin diekspose',
        skillsHelperText: 'Contoh: "Pakar digital transformation untuk SMEs" atau "Pakar carbon market development", dll.',
        biography: "Narasi biografi singkat",
        opportunities: 'Peluang kerjasama',
        opportunitiesHelperText: 'Peluang kerjasama atau bidang usaha yang ditawarkan atau mau dibantu iklankan. ' +
                                'Contohnya, sebagai pembicara, MoU Industry, dll. (Isian ini tidak akan ditampilkan di website)',
        photo: 'Foto Profil',
        photoHelperText: 'Mohon upload foto Anda dalam posisi portrait dengan resolusi tinggi (maks. 10 MB).',
        paymentProofHelperText: 'Mohon upload bukti pembayaran membership (min. £10)',
        registrationDate: 'Tanggal Registrasi',
    },
    profileFieldsValidationWarnings: {
        givenName: 'Nama depan harus diisi',
        nickname: 'Nama panggilan harus diisi',
        gender: 'Jenis kelamin harus diisi',
        hasDisability: 'Mohon pilih salah satu. Jika Anda tidak ingin menjawab, pilih "Tidak ingin menjawab"',
        disability: 'Jika Anda menjawab "Ya", mohon isi jenis disabilitas Anda',
        emailAddress: 'Alamat email harus diisi',
        validEmailAddress: 'Alamat email harus valid',
        emailAddressNotValid: 'Kami tidak menerima format alamat email ini',
        secondaryEmailAddress: 'Alamat email sekunder harus diisi',
        validSecondaryEmailAddress: 'Alamat email sekunder harus valid',
        doctrineEmailExists: 'Alamat email Doctrine ini sudah terdaftar. Mohon masukkan alamat email Doctrine yang lain.',
        doctrineEmailNotExists: 'Alamat email Doctrine ini belum terdaftar. Mohon pastikan anda memasukkan alamat yang benar bila sudah punya.',
        phoneNumber: 'Nomor telepon harus diisi',
        region: 'Wilayah harus diisi',
        status: 'Status PhD harus diisi',
        UKInstitution: 'Institusi di Inggris Raya harus diisi',
        university: 'Universitas harus diisi',
        otherUniversity: 'Universitas lainnya harus diisi jika Anda memilih "Other" di kolom universitas',
        programmeName: 'Nama program PhD harus diisi',
        phdStartDate: 'Tanggal mulai PhD harus diisi',
        phdEndDate: 'Tanggal selesai PhD harus diisi',
        fundingSource: 'Sumber pendanaan harus diisi',
        epistemologicalDisciplines: 'Bidang keilmuan harus diisi',
        epistemologicalDisciplinesOther: 'Bidang keilmuan lainnya harus diisi',
        researchInterests: 'Minat riset harus diisi',
        researchInterestsTypeErrorMessage: 'Tekan Enter untuk menambahkan minat riset pertama Anda!',
        methodologicalExpertise: 'Keahlian metodologi harus diisi',
        academicEmailAddress: 'Alamat email di universitas harus diisi',
        validAcademicEmailAddress: 'Alamat email di universitas harus valid',
        universityProfileURL: 'URL profile di universitas harus diisi. Ketik "N/A" jika tidak ada.',
        phdResearchTopic: 'Topik penelitian PhD harus diisi. Ketik "N/A" jika tidak ada.',
        phdResearchAbstract: 'Abstrak penelitian PhD harus diisi. Ketik "N/A" jika tidak ada.',
        facebook: 'URL Facebook atau ID harus valid',
        twitter: 'URL Twitter atau Username harus valid',
        instagram: 'URL Instagram atau Username harus valid',
        linkedIn: 'URL LinkedIn atau ID harus valid',
        gitHub: 'URL GitHub atau Username harus valid',
        researchGate: 'URL ResearchGate atau Username harus valid',
        orcidID: 'ORCID ID harus valid',
        inputType: 'Jenis input harus diisi',
        title: 'Judul harus diisi',
        year: 'Tahun harus diisi',
        membershipType: 'Jenis keanggotaan harus diisi',
        photo: 'Foto profil harus diisi',
        paymentProof: 'Bukti pembayaran harus diisi',
    },
    genders: {
        female: 'Perempuan',
        male: 'Laki-laki',
        other: 'Tidak ingin menyebutkan',
    },
    disabilities: {
        physical: 'Disabilitas Fisik',
        intellectual: 'Disabilitas Intelektual',
        mental: 'Disabilitas Mental',
        speaking: 'Disabilitas Sensorik Wicara',
        hearing: 'Disabilitas Sensorik Rungu',
        visual: 'Disabilitas Sensorik Netra',
    },
    status: {
        active: 'Mahasiswa/i PhD Aktif',
        alumni: 'Alumni PhD',
        postdoc: 'Postdoctoral',
        faculty: 'Dosen/akademisi',
        professional: 'Profesional',
        other: 'Lainnya',
    },
    fundingSource: {
        other: 'Lainnya',
    },
    methodologicalExpertise: {
        qualitativeStudy: 'Studi Kualitatif (narrative, visual, etnography, multimodal etnography)',
        quantitativeStudy: 'Studi Kuantitatif',
        mixedMethods: 'Metode Campuran',
    },
    jobTitle: {
        lecturerPNS: 'Dosen (PNS)',
        lecturerNonPNS: 'Dosen (Non-PNS)',
        researcher: 'Peneliti / Post-Doc',
        consultant: 'Konsultan',
        bureaucrat: 'Birokrat / Aparatur Negara',
        army: 'TNI / Polisi',
        officerPrivate: 'Pegawai (Swasta)',
        officerBUMN: 'Pegawai (BUMN)',
        entrepreneur: 'Wirausahawan',
        other: 'Lainnya',
    },
    preCensusLandingPage: {
        title: 'Selamat datang di Sensus Doctrine UK!',
        body: 'Mohon kembali lagi ke halaman ini pada 9 April 2023 untuk mengisi sensus.',
    },
    censusL1: {
        title: 'Sensus Doctrine UK 2023',
        introduction1: 'Selamat datang ke dalam sensus data anggota Affiliate Doctrine UK.',
        introduction2: 'Dengan terdaftar menjadi anggota Affiliate, anda dapat memperoleh akses tergabung dalam diskusi ' +
            'klaster keilmuan, WhatsApp group PhD student dari Indonesia di UK, dan beragam fasilitas gratis lainnya ' +
            '(Doctrine summit, seminar, dll).',
        termsAndConditionsTitle: 'Apakah anda setuju dengan syarat dan ketentuan berikut?',
        termsAndConditionsBody1: 'Dengan mengisi form ini, anda setuju bahwa anda:',
        termsAndConditionsBody2a: '1. Menyatakan kesediaan memberikan datanya dan menjadi anggota affiliate member Doctrine UK.',
        termsAndConditionsBody2b: '1. Menyatakan kesediaan memberikan datanya dan menjadi anggota chartered member Doctrine UK.',
        termsAndConditionsBody3: 'SK status keanggotaan Doctrine UK dapat diakses di tautan berikut:',
        termsAndConditionsBody4: '2. Bersedia mematuhi AD/ART Doctrine UK yang terlampir di tautan berikut:',
        termsAndConditionsBody5: '3. Memperbolehkan data anda untuk dikelola sebagaimana tertulis dalam dokumen ' +
            'Tata Kelola Data Doctrine UK yang terlampir di tautan berikut:',
        termsAndConditionsBody6: 'Jika Anda memiliki pertanyaan atau kesulitan ketika mengisi sensus ini, silakan hubungi ' +
            'kami melalui grup WhatsApp: ',
    },
    censusL1Thanks: {
        title: 'Terima kasih telah mengisi data sensus anda!',
        body1: 'Data anda sudah disubmit dan akan direview oleh Doctrine UK. ' +
            'Email konfirmasi sudah dikirim ke alamat email yang anda berikan.',
        body2Active: 'Anda sekarang adalah anggota affiliate Doctrine UK. Bila ada pertanyaan, silakan hubungi kami dengan ' +
            'bergabung ke grup WhatsApp: ',
        body2Yore: 'Anda sekarang adalah member of yore Doctrine UK. Bila ada pertanyaan, silakan hubungi kami dengan ' +
            'bergabung ke grup WhatsApp: ',
        subtitleChartered: 'Apakah anda tertarik untuk menjadi anggota Chartered Doctrine UK?',
        chartered1: 'Untuk menjadi anggota Chartered, anda harus melakukan pembayaran keanggotaan sebesar minimal £10 per tahun. ' +
            'Dengan menjadi anggota Chartered, anda akan mendapat fasilitas tambahan berupa: ',
        charteredFac1: 'Email anggota Doctrine (username@doctrineuk.org)',
        charteredFac2: 'Google Drive dengan storage yang besar',
        charteredFac3: 'Kartu Keanggotaan Digital',
        charteredFac4: 'Office 365',
        charteredFac5: 'Kesempatan untuk mempublish Standard Research Profile di halaman ',
        subtitleYorePremium: 'Apakah anda tertarik untuk menjadi anggota Member of Yore Premium Doctrine UK?',
        yorePremium1: 'Untuk menjadi Member of Yore Premium, anda harus melakukan pembayaran keanggotaan sebesar minimal £20 per tahun. ' +
            'Dengan menjadi Member of Yore Premium, anda akan mendapat fasilitas tambahan berupa: ',
        yorePremiumFac1: 'Email anggota Doctrine (username@yore.doctrineuk.org)',
        yorePremiumFac2: 'Publish sebagai Top Research Profile di halaman ',
        yorePremiumFac3: 'Top Priority untuk layanan kerjasama sebagai Pemateri dan MoU Industri',
        yorePremiumFac4: 'Google Drive dengan storage yang besar',
        yorePremiumFac5: 'Kartu Keanggotaan Digital',
        yorePremiumFac6: 'Office 365',
        notInterestedChartered: 'Klik tombol di bawah ini untuk menjadi anggota Chartered Doctrine UK!',
        notInterestedYorePremium: 'Klik tombol di bawah ini untuk menjadi Member of Yore Premium Doctrine UK!',
        interestButtonChartered: 'Saya tertarik untuk jadi anggota chartered',
        interestButtonYorePremium: 'Saya tertarik untuk jadi member of yore premium',
        interested1Chartered: 'Terima kasih atas ketertarikan anda untuk menjadi anggota chartered!',
        interested1YorePremium: 'Terima kasih atas ketertarikan anda untuk menjadi member of yore premium!',
        interested2: 'Kami sudah mengirim email dengan informasi selanjutnya. Mohon ikuti petunjuk di email.'
    },
    censusL2: {
        titleChartered: 'Sensus Doctrine UK 2023 - Chartered Member',
        titleYorePremium: 'Sensus Doctrine UK 2023 - Member of Yore Premium',
        subtitle: 'mohon isi form di bawah ini untuk menyelesaikan proses pendaftaran anda.',
    },
    censusL2Thanks: {
        titleChartered: 'Terima kasih sudah mendaftar menjadi anggota Chartered!',
        titleYorePremium: 'Terima kasih sudah mendaftar menjadi Member of Yore Premium!',
        body1: 'Data anda sudah disubmit dan bukti pembayaran anda akan direview oleh Doctrine UK. ' +
            'Email konfirmasi sudah dikirim ke alamat email yang anda berikan.',
        body2: 'Mohon tunggu kami untuk mengontak anda dengan informasi lebih lanjut. Apabila ada pertanyaan, silakan hubungi kami dengan ' +
            'bergabung ke grup WhatsApp: ',
    },
    membershipTypeDialog: {
        title: 'Klasifikasi Keanggotaan dan Fasilitasnya',
        statutory1: 'Semua mahasiswa PhD on-going di UK',
        statutory2: 'Tidak perlu daftar',
        affiliate1: 'Mahasiswa PhD on-going di UK yang tergabung ke grup WA, anggota klaster keilmuan, dll (tidak wajib)',
        affiliate2: 'Gratis',
        affiliate3: 'Mengikuti sensus Doctrine UK',
        chartered1: 'Anggota aktif yang ingin mendapatkan fasilitas Doctrine UK: email, Google Workspace, Google Drive, Kartu Keanggotaan Digital, ' +
            'serta publikasi profile researcher di website',
        chartered2: 'Pengurus eksekutif, Ketua, dan Wakil ketua Klaster Keilmuan',
        chartered3: 'Melengkapi form lanjutan sensus untuk profile researcher website',
        chartered4: 'Berkontribusi minimal sebesar £10 per tahun untuk membership fee',
        memberOfYoreStandard1: 'Terbuka untuk',
        memberOfYoreStandard1a: 'alumni PhD dari institusi di UK yang berada di UK atau di luar UK;',
        memberOfYoreStandard1b: 'Post-Doctoral atau Faculty Member di UK;',
        memberOfYoreStandard1c: 'atau alumni PhD yang sedang bekerja di UK',
        memberOfYoreStandard2: 'Mengikuti sensus Doctrine UK',
        memberOfYorePremium1: 'Terbuka untuk Member of Yore yang ingin mendapatkan fasilitas Doctrine UK: email, Google Workspace, Google Drive, Kartu Keanggotaan Digital, ' +
            'serta publikasi profile researcher di website',
        memberOfYorePremium2: 'Melengkapi form lanjutan sensus untuk profile researcher website',
        memberOfYorePremium3: 'Berkontribusi minimal sebesar £20 per tahun untuk membership fee',
    },
    vote: {
        blurb1: 'Silakan pilih kandidat yang Anda inginkan di pemilu ini. ' +
            'Anda dapat melakukan ini dengan menekan tombol plus di sebelah nama kandidat. ' +
            'Anda juga dapat menghapus pilihan Anda dengan menekan tombol minus di sebelah nama kandidat.',
        blurb2: 'Anda dapat mengklik checkbox "Tunjukan Universitas dan Jabatan" untuk untuk melihat universitas dan jabatan saat ini dan sebelumnya dari kandidat. \n' +
            'Anda dapat sort atau filter kandidat dengan mengklik tiga titik di sebelah kanan kolom.',
        blurb3: 'Mohon scroll sampai ke ujung bawah halaman untuk melihat tombol "Kirim Voting". ' +
            'Setelah Anda mengirim voting, Anda tidak dapat mengubah pilihan Anda. \n',
        chartered: 'Karena Anda adalah anggota Chartered, Anda memiliki 3 hak suara yang dapat digunakan. ' +
            'Ketiga suara tersebut dapat digunakan untuk kandidat yang berbeda atau kandidat yang sama.\n',
        totalNumCandidates: 'Jumlah kandidat',
        maxVotes: 'Jumlah Hak Suara',
        usedVotes: 'Hak Suara yang Sudah Digunakan',
        availableVotes: 'Hak Suara yang Tersisa',
        checkbox: 'Tunjukkan Universitas dan Jabatan',
        alreadyVoted: 'Anda sudah memilih di pemilu ini.',
        name: 'Nama',
        university: 'Universitas',
        role: 'Jabatan',
        thanks: 'Terima kasih sudah berpartisipasi dalam pemilu!',
        end: 'Periode pemilu telah berakhir.',
    },
    voteSummaryDialog: {
        title: 'Ringkasan Voting',
        description: 'Anda akan memilih kandidat berikut ini:',
        warning: "Pastikan Anda memilih kandidat yang Anda inginkan. Anda tidak dapat mengubah pilihan Anda setelah ini.",
    },
    buttons: {
        'submit': 'Kirim',
        'search': 'Cari',
        'remove': 'Hapus',
        'addItem': 'Tambah',
        'saveChanges': 'Simpan Perubahan',
        'openMembershipTypes': 'Klik untuk melihat jenis-jenis keanggotaan!',
        'openUKRegionsMap': 'Buka Peta Wilayah UK',
        'agree': 'Setuju',
        'disagree': 'Tidak Setuju',
        'submitVotes': 'Kirim Voting',
    },
    booleanOptions: {
        yes: 'Ya',
        no: 'Tidak',
        preferNotToSay: 'Memilih untuk tidak menjawab',
    },
    manageMembership: {
        startDate: 'Tanggal Mulai',
        endDate: 'Tanggal Selesai',
        status: 'Status',
        renewMembership: 'Perpanjang Keanggotaan',
        renewQuestion: 'Apakah Anda ingin memperpanjang keanggotaan?',
        renewDesc1: 'Apabila Anda ingin memperpanjang keanggotaan Anda, mohon melakukan pembayaran minimal £10 (untuk chartered member) atau ' +
            '£20 (untuk member of yore premium) ke rekening bank berikut:',
        renewDesc2: 'Nama: Doctrine UK',
        renewDesc3: 'dan kemudian upload bukti pembayaran di bawah ini.',
    }
}

export default id;
